<template>
  <div class="">
    <Card class="p-m-2">
      <template #title>
        Excel İndir
      </template>
      <template #content>
        <div class="p-grid ">
          <div class="w-min md:w-max p-col-12 p-md-6 p-lg-3 p-d-flex p-jc-center">
            <div class="KullanimKart p-grid">
              <div class="p-col-12 p-d-flex p-jc-center Font600">
                &nbsp;&nbsp;&nbsp;<p>Template</p>&nbsp;&nbsp;&nbsp;
              </div>
              <div class="p-col-12 p-d-flex p-jc-center Font600">
                <Button label="İndir" class="p-button-danger p-button-text p-button-sm"
                  @click="this.Indir('Template1');" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
export default {
  data() {
    return {

    };
  },
  methods: {
    Indir(Tip) {
      switch (Tip) {
        case 'Template1':
            this.$router.push({ path : '/ExcelIndir/Template1' });
          break;
        case '':

          break;
        default:
          break;
      }
    }
  },
  mounted() {
  },
  components: {
  },
};
</script>

<style scoped>
.KullanimKart {
  box-shadow: 0 4px 8px 0 rgba(192, 202, 183, 0.575);
  text-align: center;
  background-color: #8bf0a9d3;
}

.Font600 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
}

.Font300 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Font300-link {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}
</style>